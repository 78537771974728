import { useEffect } from 'react';
import { URLS } from '../config/constants';

const RedirectToDefaultApp = ({ defaultApp }) => {
  useEffect(() => {
    window.open(defaultApp ? defaultApp.url : URLS.fallbackLogin, '_SELF');
  }, [defaultApp]);
  return null;
};

export default RedirectToDefaultApp;
