import Auth0Provider from '@topconagriculture/auth/build/core/auth0';
import graphqlClient from '@topconagriculture/graphql-client/build/client/clientConfiguration';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
//import Apps from './Apps';
import AppsRest from './AppsRest';
import { AUTH0, GRAPHQL_CLIENTS_NAMES } from '../config/constants';
import '../locales/i18n';

const Login = () => {
  const [authInfo, setAuthInfo] = useState(null);

  const onLoginDone = (tokens) => {
    graphqlClient.setTokens([
      { name: GRAPHQL_CLIENTS_NAMES.graphqlGateway, tokens },
    ]);
    setAuthInfo(tokens);
  };

  return (
    <Auth0Provider
      onLoginDone={onLoginDone}
      config={{
        clientId: AUTH0.clientId,
        domain: AUTH0.domain,
        audience: AUTH0.audience,
        scope: AUTH0.scope,
        environment: AUTH0.environment,
        redirectUri: AUTH0.redirect_uri,
      }}
    >
      {authInfo && <AppsRest authInfo={authInfo} />}
    </Auth0Provider>
  );
};

export default withTranslation()(Login);
