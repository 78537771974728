import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import GlobalStyle from '../assets/styles/GlobalStyle';
import '../locales/i18n';
import tapLogo from '../assets/images/tap_logo.svg';
import patternDx from '../assets/images/pattern_hexagon_2.svg';
import patternSx from '../assets/images/pattern_hexagon_1.svg';
import Login from '../auth/Login';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  width: 100%;
  background: linear-gradient(to right, #19375b 0%, #2d577b 48%, #19375b 100%);
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 100;
  min-height: 100vh;
`;

const PatternLeft = styled.img`
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 101;
  width: 30%;
`;

const PatternRight = styled.img`
  position: absolute;
  right: 0;
  bottom: 10%;
  z-index: 101;
  width: 20%;
`;

const pulse = keyframes`
  0% {width: 250px;}
  100% {width: 350px;}
}`;

const TapLogo = styled.img`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  z-index: 105;
  animation-name: ${pulse};
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 400px;
  border-radius: 10px;
  z-index: 105;
  padding: 20px;
  min-height: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoginPage = () => {
  return (
    <MainContainer>
      <PatternLeft src={patternSx} />
      <PatternRight src={patternDx} />
      <Content>
        <TapLogo alt="TAP" src={tapLogo} />
        <Login />
      </Content>
      <GlobalStyle />
    </MainContainer>
  );
};

export default withTranslation()(LoginPage);
