import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/constants';
import RedirectToDefaultApp from './RedirectToDefaultApp';
import axios from 'axios';

const AppsRest = ({ authInfo }) => {
  console.log('API_BASE_URL: ', API_BASE_URL);
  console.log('authInfo: ', authInfo);
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: { authorization: `Bearer ${authInfo.token}` },
  });

  const [apps, setApps] = useState(null);

  useEffect(() => {
    axiosInstance.get(`/users/${authInfo.userId}/apps`).then((res) => {
      console.log('res: ', res);
      setApps(res.data);
    });
  }, [authInfo.userId, axiosInstance]);

  if (apps && apps.length > 0) {
    const defaultApp = apps ? apps.find((app) => !!app.isPreferred) : null;
    return <RedirectToDefaultApp defaultApp={defaultApp} />;
  } else {
    return null;
  }
};

export default AppsRest;
